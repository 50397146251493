<template>
  <div v-if="label">
    <v-card-title>
      selected label
    </v-card-title>
    <v-card-text>
      <v-row>
        <v-col col="12" xl="2" lg="2" md="3" sm="3" xs="4">
          <canvas id="ml_qr"></canvas>
        </v-col>
        <v-col align="start" justify="end">
          <v-row>
            <div>shows: {{label ? label.Attr.redirectCount : ""}}</div>
          </v-row>
          <!--<v-row>
            <div>start date of use: {{label ? label.UseStarted : ""}}</div>
          </v-row>-->
          <v-row v-if="label.Expired">
            <div>expiration: {{label ? localtime(label.Attr.Expired) : ""}}</div>
          </v-row>
          <v-row align="end" justify="start">
            <v-btn
              :to="'/edit/'+label.ID"
            >Edit
            </v-btn>
          </v-row>
        </v-col>
      </v-row>
      <p>URL: {{label ? label.Attr.redirectURL : ""}}</p>
    </v-card-text>
  </div>
</template>

<script>
export default {
  props: {
    label: {
      type: Object,
      default: null,
    }
  }
}
</script>