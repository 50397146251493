<template>
  <div class="home" v-if="$isLogin && $isEmailVerified">
    <v-card>
      <v-card-title>Labels</v-card-title>
      <SelectedLabel
        :label="this.label"
      />
      <v-card-text>
        <PaginatedList
          name="Devices"
          ref="Devices"
          :itemName="this.itemName"
          :getIdToken="this.getIdToken"
          :getTotalNumber="this.getTotalNumber"
          :getItems="this.getItems"
          :requestOne="this.requestOneOfNotActivated"
          @itemSelected="itemSelected"
        />
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
/* eslint-disable no-console */
/* eslint-disable no-unused-vars */
/* eslint-disable no-unused-vars */

// @ is an alias to /src
//import firebase from "firebase/app";
//import "firebase/auth";
import * as firebase from "firebase";
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'
import {PaginatedList} from 'vuetifypaginatedlist'
import SelectedLabel from '@/components/SelectedLabel'

import axios from 'axios';
import QRCode from 'qrcode';
//import "firebase/firestore";

var sprintf = require('sprintf-js').sprintf//,
//    vsprintf = require('sprintf-js').vsprintf

export default {
  name: 'home',
  components: {
    PaginatedList,
    SelectedLabel
  },
  mixins: [IdToken],
  data: function () {
    return {
      selectedItem: "",
 //     labels: "",
      label: null,
    }
  },
  methods: {
    /**
     * For PaginatedList
     * 
     */
    itemSelected: function(item){
//      this.$deviceID = item.ID
//      this.$refs.device.open(item.ID)
      console.log("item", item)
      this.selectedItem = item

    },

    itemName(item){
      if (item === null){
        return ""
      } else {
        return item
      }
    },
    getTotalNumber: async function(idToken){
      const url=sprintf("https://ml.uedasoft.com/v1/gettotalnumberofownedlabels/%s",idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("getTotalNumberOfItems", data)
        console.log("totalnumber", data.totalnumber)
        //this.totalNumber = data.totalnumber
        return data.totalnumber
      } else {
        return 0
      }
    },
    getItems: async function(idToken, itemsPerPage, page){
      const url=sprintf("https://ml.uedasoft.com/v1/ownedLabels/%d/%d/%s", 
                        itemsPerPage, 
                        (page -1)*itemsPerPage,
                        idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("data", data)
        return data.labels
      } else {
        return []
      }
    },
    requestOneOfNotActivated: async function(idToken){
/*      const url=sprintf("https://ml.uedasoft.com/requestone/%s", 
                        idToken)
      const res = await fetch(url)
      if (res.status == 200) {
        const data = await res.json()
        console.log("result", data)
        //this.items = data.devices
        return [data.device]
      } else {
        return []
      }*/
    },

/*    log: function(str){
      console.log(str)
    },*/
    showML: function(label_str){
      console.log("label_str = " + label_str)
      var currentUrl = "https://ml.uedasoft.com/ml/" + label_str;
        QRCode.toCanvas(document.getElementById('ml_qr'),
          currentUrl, { toSJISFunc: QRCode.toSJIS }, function (error) {
          if (error) console.error(error)
          console.log('success!')
        })
    },
    localtime: function(time_str){
      let dt = new Date(Date.parse(time_str))
      return dt.toLocaleDateString()
    }
  },
  computed: {
    externalIDforShow: function(){
      return this.labels[this.selectedItem] ? this.labels[this.selectedItem]['ExternalID'] : "";
    },
  },
/*  async mounted() {
    const idToken = await this.getIdToken()
    const url = "https://ml.uedasoft.com/v1/ownedLabels/" + idToken
    const res = await fetch(url)
    console.log(res)
    const data = await res.json()
    console.log("data",data)
    this.externalIDs = data.labels
  },
*/
  watch: {
    selectedItem: function(newSelectedItem, oldSelectedItem) {
      this.showML(newSelectedItem);
      axios
      .get('https://ml.uedasoft.com/v1/labelinfo/' + newSelectedItem)
      .then(response => {
/*
        this.label = {
          owner:          response.data.owner,
          redirectCount:  response.data.redirectCount,
          redirectURL:    response.data.redirectURL,
          externalID:     response.data.externalID,
          Tag:            response.data.Tag,
        };
*/
        console.log("response", response)
        this.label = response.data;
/*
        console.log("response.data = ",response.data);        
        if (response.data.update == "ok") {
          this.acceptOKresponse = true;
        }
*/
      });
    }
  }
}
</script>
