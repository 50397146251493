<template>
  <div  v-if="$isLogin && $isEmailVerified"> <!-- v-if="loggedinAndVerified"> -->
    <h1>This is an register page</h1>
    <div>Label id {{ $route.params.id }}</div>
    <div>UserID {{ $user_id }}</div>
    <p v-if="this.registerOKresponse" class="green--text font-weight-medium">
      Owner registeration is succeeded!<br> You can edit it at <router-link :to="'/edit/'+$route.params.id">Edit</router-link> page, manage all of your owned labels at <router-link to="/labels">Labels</router-link> page.
    </p>
    <v-btn v-if="user" @click="registerAsOwner">Register as owner</v-btn>
  </div>
</template>

<script>
// @ is an alias to /src
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'                   // If firebase is from CDN
import * as firebase from "firebase";
//import "firebase/auth";
//import axios from 'axios';

export default {
  mixins: [IdToken],
  data: function () {
    return {
      user: "けろけろ",
      registerOKresponse: false,
    }
  },
  methods:{
    registerAsOwner: async function() {
      /*
        var params = new URLSearchParams();
        params.append('userID', this.$internal_user_id );
        params.append('externalID',this.$route.params.id);
        axios
        .post('https://ml.uedasoft.com/v1/registerOwner', params)
        .then(response => {
          console.log(response);
          if (response.data.update == "ok") {
            this.registerOKresponse = true;
          }
        }).catch(function (error) {
          console.log(error);
        });
      */
      const idToken = await this.getIdToken()
      console.log("idToken", idToken)
      const url = "https://ml.uedasoft.com/v1/registerowner/" + idToken
      const res = await fetch(url, {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({"labelID": this.$route.params.id})
      })
      console.log(res)
/*
      if (res.status == 200) {
        console.log("consume point completed")
        var data = await res.json();
        console.log("data",data)
        this.$pointState = data
      }
*/
    }
  },
  mounted() {
    firebase.auth().onAuthStateChanged((user) => {

      if (user) {
        this.user = user;
      }
    })
  },
  computed: {
  },

}
</script>