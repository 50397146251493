<template>
  <div class="edit" v-if="$isLogin && $isEmailVerified">
    <span class="display-1">Edit Label</span>
    <span class="caption"> ID: {{ $route.params.externalID }}</span>
<!--    {{loggedinAndVerified}} -->
    <v-card  v-if="$isLogin && $isEmailVerified">
      <v-card-title>Label Status</v-card-title>
      <v-card-text>Label id {{ $route.params.externalID }}</v-card-text>
      <v-card-text v-if="label.UseStarted">Start date of use {{ localtime(label.UseStarted) }}</v-card-text>
      <v-card-text v-if="label.Expired">Expiration date {{ localtime(label.Expired) }}</v-card-text>
      <v-card-title>redirect URL</v-card-title>
      <v-card-text>
<!--      <SetURL
        v-if="label"
        :label=label
      >
      </SetURL>-->
      <SetURL
        v-if="label"
        :externalID=label.ID
        :getIdToken=this.getIdToken
      >
      </SetURL>
      <v-btn
        @click="buy"
      >
        buy
      </v-btn>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import SetURL from '@/components/SetURL.vue'
import axios from 'axios';
import firebase from "firebase/app";
//import "firebase/auth";
import {loadStripe} from '@stripe/stripe-js';
import IdToken from 'vue2-faui-user-fe/src/mixins/IdToken'

export default {
  props: ['externalID'],
  mixins: [IdToken],
  components: {
    SetURL,
  },
  data: function () {
    return {
      label: "",
/*
      sessionParams: {
        lineitems: [
          {
            priceID: "price_1ISG3jG8LX1Yc05997dAXGkc",
            quantity: 1,
          }
        ],
        metadatas: {
          eventHandler: "ml",
//          owner: owner,
          liveOrtest: this.$stripeEnv,
//          liveOrtest: "けろけろ",

          productCategory: "ml",
          totalQuantity: 1,
        }
      }
*/
    }
  },
  computed: {
    sessionParams: function(){
      return {
        lineitems: [
          {
            priceID: "price_1ISG3jG8LX1Yc05997dAXGkc",
            quantity: 1,
          }
        ],
        metadatas: {
          eventHandler: "ml",
//          owner: owner,
          liveOrtest: this.$stripeEnv,
//          liveOrtest: "けろけろ",

          productCategory: "ml",
          totalQuantity: "1",
        }
      }
    }
  },
  async mounted() {
    console.log("externalID = ",this.$route.params.externalID)
    axios
//      .get('https://ml.uedasoft.com/v1/labelinfo/' + this.externalID)
      .get('https://ml.uedasoft.com/v1/labelinfo/' + this.$route.params.externalID)
      .then(response => {
        this.label = response.data;
      });
    this.stripe = await loadStripe(this.$stripe);
//    console.log("process.env.VUE_APP_apiKey", this.$stripe)
//    this.stripe = await loadStripe("pk_test_TqsPguNyJ9ezM50fWY5TVUvU");
  },
  methods: {
    localtime: function(time_str){
      let dt = new Date(Date.parse(time_str))
      return dt.toLocaleDateString()
    },
    buy: function(){
      firebase.auth().currentUser.getIdToken(/* forceRefresh */ true).then((idToken) => {
      axios
        .post('https://ml.uedasoft.com/v1/sessionID/' + idToken, this.sessionParams)
        .then(response => {
          this.response = response;
          console.log(response);
          console.log(response.status)
          if (response.status == 200) {
            console.log("response = ", response)
            this.stripe.redirectToCheckout({sessionId: response.data.SessionID})
          } else {
            console.log("accept NG")
          }
        })
        .catch(function(error) {
          console.log(error);
        });
      });
    }
  }
}
</script>
